import React, { useState, useEffect, useContext } from 'react';
import SelectSearch from 'react-select-search/dist/cjs';
import Constants from './constants';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import addIcon from '../../styles/images/addIcon.svg';
import filterIcon from '../../styles/images/filterIcon.svg';
import Pagination from '../../features/Pagination';
import Accordion from '../../features/Accordion';
import helperFunctions from '../../utils/helperFunctions';
import LabelledSelect from '../../components/LabelledSelect';
import LabelledInput, { LabelledInputTypeText, LabelledInputTypeNumber } from '../../components/LabelledInput';
import Form from '../../components/Form';
import AuthContext from '../../context/AuthContext';
import userManagementAPIs from '../../services/user-management.service';
import SearchBar from '../../features/SearchBar';
import SortDropdown from '../../features/SortDropdown';
import UserManagementFilterPanel from '../../features/UserManagementFilterPanel';
import Toast from '../../components/Alerts/Toast/Toast';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import { specialAccountPrivileges } from '../../config/constants';
import StatusLabel from '../../components/StatusLabel';
import gatewayAPIs from '../../services/gateway.service';
import GeneralConstants from '../../utils/generalConstants';

const initialState = {
    firstname: '',
    surname: '',
    email: '',
    jobTitle: '',
    department: '',
    userRole: '',
};

const UserManagement = () => {
    const [usersList, setusersList] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [values, setValues] = useState({});
    const authContext = useContext(AuthContext);
    const [editClicked, setEditClicked] = useState(false);
    const [searchFilteredDataSet, setSearchFilteredDataSet] = useState([]);
    const [performFilters, setPerformFilters] = useState(false);
    const [performSort, setPerformSort] = useState(true);
    const [currentPageNum, setCurrentPageNum] = useState(0);
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);
    const [noFilterResults, setNoFilterResults] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [tabletMode, setTabletMode] = useState({ matches: window.innerWidth <= 840 });
    const [mobileMode, setMobileMode] = useState({ matches: window.innerWidth <= 600 });
    const [departmentList, setDepartmentList] = useState([]);
    // const [specialPrivilegesList, setSpecialPrivilegesList] = useState([]);
    const { id, accountType } = authContext.user;

    useEffect(() => {
        const tabletMediaQuery = window.matchMedia('(max-width: 840px)');
        const mobileMediaQuery = window.matchMedia('(max-width: 600px)');

        tabletMediaQuery.addEventListener('change', setTabletMode);
        mobileMediaQuery.addEventListener('change', setMobileMode);

        return () => {
            tabletMediaQuery.removeEventListener('change', setTabletMode);
            mobileMediaQuery.removeEventListener('change', setMobileMode);
        };
    }, []);

    const getPrettyspecialPrivilege = (privilege) => specialAccountPrivileges.filter(
        (sPrivilege) => sPrivilege.value === privilege,
    )[0]?.name;

    const accessLevels = ['Basic', 'Intermediate', 'Advanced', 'Admin'];

    const fetchUsers = async () => {
        try {
            // const authorities = await userManagementAPIs.getAllAccountByType(
            //     accountType.toLowerCase(),
            // );
            const departmentslist = await userManagementAPIs.listAllDepartments();
            if (departmentslist.status === 200) {
                const department = departmentslist.data.departments;
                setDepartmentList(department.map((dept) => dept.departmentName));
            }
            // TODO: remove state and directly use constant to generate dropdown values.
            // setSpecialPrivilegesList(specialAccountPrivileges.map((item) => item.name));
            const responseData = await userManagementAPIs.getAllUsersByAccountForAdmin('approved');
            if (responseData.status === 200) {
                setusersList(() => {
                    if (responseData.data && responseData.data.length > 0) {
                        for (let i = 0; i < responseData.data.length; i++) {
                            responseData.data[i].username = `${responseData.data[i].contactDetails.firstname} ${responseData.data[i].contactDetails.surname}`;
                            responseData.data[i].firstname = `${responseData.data[i].contactDetails.firstname}`;
                            responseData.data[i].surname = `${responseData.data[i].contactDetails.surname}`;
                        }
                    }
                    return responseData.data;
                });
            }
        } catch (err) {
            setError('Something went wrong. We couldn\'t get the users data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const toggleFilterPanel = () => setFilterPanelOpen(!filterPanelOpen);

    const handleSearchFilterResult = (filteredDataSet) => {
        if (filteredDataSet === null) {
            setNoFilterResults(true);
            setSearchFilteredDataSet(usersList);
        } else if (filteredDataSet.length === 1 && filteredDataSet[0] === 'Backspace') {
            setSearchFilteredDataSet(usersList);
            if (!performFilters) {
                setPerformFilters(true);
            }
            if (!performSort) {
                setPerformSort(true);
            }
        } else {
            if (noFilterResults) {
                setNoFilterResults(false);
            }
            setSearchFilteredDataSet(filteredDataSet);
            setPerformFilters(true);
            setPerformSort(true);
            if (filteredDataSet.length > 1) {
                setCurrentPageNum(0);
            }
        }
    };

    const handleSortResult = (sortedDataSet) => {
        if (searchFilteredDataSet.length > 0) {
            setSearchFilteredDataSet(sortedDataSet);
        } else {
            setusersList(sortedDataSet);
        }
        setPerformSort(false);
    };

    const handleFilterPanelResult = (filteredDataSet) => {
        if (filteredDataSet === null) {
            setNoFilterResults(true);
        } else if (filteredDataSet.length === 1 && filteredDataSet[0] === 'NoFiltersToPerform' && performFilters) {
            setPerformFilters(false);
        } else {
            if (filteredDataSet.length > 0) {
                setSearchFilteredDataSet(filteredDataSet);
            } else {
                setSearchFilteredDataSet(usersList);
            }

            if (noFilterResults) {
                setNoFilterResults(false);
            }

            if (filteredDataSet.length > 1) {
                setPerformSort(true);
                setCurrentPageNum(0);
            }
        }

        setPerformFilters(false);
    };

    const handlePagination = (pageNumber) => {
        setCurrentPageNum(pageNumber);
    };

    const calculateNumDisplayPages = () => {
        if (mobileMode.matches) {
            return 5;
        }

        if (tabletMode.matches) {
            return 8;
        }

        return 10;
    };

    const handleEdit = (data) => {
        setShowUpdateModal(true);
        setModalData(data);
        setEditClicked(true);
    };

    const handleEnableDisable = async (userID, status) => {
        Confirm(async () => {
            try {
                const response = await userManagementAPIs.enableDisableUser(userID, !status);
                if (response) {
                    Toast.fire({
                        icon: 'success',
                        titleText: `User ${status ? 'disabled' : 'enabled'} successfully.`,
                    });
                    fetchUsers();
                }
            } catch (err) {
                Toast.fire({
                    icon: 'error',
                    titleText: `unable to ${status ? 'disable' : 'enable'} user.`,
                });
            }
        }, {
            subTitle: `Are you sure you want to ${status ? 'disable' : 'enable'} the user`,
        });
    };

    const handleResetPassword = async (email, isUserEnable) => {
        if (!isUserEnable) {
            Toast.fire({
                icon: 'error',
                titleText: Constants.resetPasswordDisabledUserError,
            });
        } else {
            const res = await userManagementAPIs.getVerificationCode(email);
            const isSuccess = res.status === 200;
            Toast.fire({
                icon: isSuccess ? 'success' : 'error',
                titleText: isSuccess ? Constants.resetPasswordSuccess
                    : Constants.resetPasswordError,
            });
        }
    };

    const generateAccordionSectionHeader = (data) => <>
        <p className='headerTitle title'>{`${data.contactDetails.firstname} ${data.contactDetails.surname}`}</p>
        {!data.isUserEnable
        && <StatusLabel
            id={`${data.userID}`}
            color={'amber'}
            labelTxt={'Disabled'}
        />}
    </>;

    const generateAccordionSectionContent = (data) => <>
        <div className='userAccordionSectionContentRow'>
            <div className='userAccordionSectionContentData'><p className='label body'>Job title: </p><p className='value caption'>{data.jobTitle}</p></div>
            <div className='userAccordionSectionContentData'><p className='label body'>Email: </p><p className='value caption'>{data.email}</p></div>
        </div>
        <div className='userAccordionSectionContentRow'>
            <div className='userAccordionSectionContentData'><p className='label body'>Department: </p><p className='value caption'>{data.departmentName}</p></div>
            <div className='userAccordionSectionContentData'><p className='label body'>Access Level: </p><p className='value caption'>{helperFunctions.camel2title(data.userRole)}</p></div>
        </div>
        {accountType.toLowerCase() !== 'supplier' && <div className='userAccordionSectionContentRow'>
            <div className='userAccordionSectionContentData'><p className='label body'>Privileges: </p><p className='value caption'>{data?.privilege?.length > 0 ? data?.privilege?.map((el, idx) => (idx + 1 === data.privilege.length ? `${getPrettyspecialPrivilege(el.userRole)}` : `${getPrettyspecialPrivilege(el.userRole)}, `)) : 'None'}</p></div>
        </div> }
        <div className='buttonItem'>
            <div className='userButtonsContainer'>
                <div className='userPasswordWrapper'>
                    <Button
                        id={'temp-btn'}
                        label={Constants.resetPassword}
                        handleClick={() => handleResetPassword(data.email, data.isUserEnable)}
                        variant={'secondary'}
                    />
                </div>
                <div className='userButtonsWrapper'>
                    {id === data.userID
                        ? <></>
                        // ? <Button
                        //     id={'remove-btn'}
                        //     label={'Remove'}
                        //     handleClick={() => handleEnableDisable(data.userID, data.email)}
                        //     variant={'primary'}
                        //     additionalVariant={'red'}
                        //     disabled
                        // />
                        : <Button
                            id={'remove-btn'}
                            label={data.isUserEnable ? 'Disable' : 'Enable'}
                            handleClick={() => handleEnableDisable(data.userID, data.isUserEnable)}
                            variant={'primary'}
                            additionalVariant={data.isUserEnable ? 'red' : ''}
                        />
                    }
                    <Button
                        id={'edit-btn'}
                        label={'Edit'}
                        handleClick={() => handleEdit(data)}
                        variant={'primary'}
                    />
                </div>
            </div>
        </div>
    </>;

    const handleModal = () => {
        setShowModal(true);
    };

    const handleContactDetails = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });

        setModalData({
            ...modalData,
            contactDetails: {
                ...modalData.contactDetails,
                [name]: value,
            },
        });
    };
    const handleChange = (event, section) => {
        const { name } = event.target;
        const { value } = event.target;

        setValues({
            ...values,
            [name]: value,

        });

        if (section) {
            setModalData({
                ...modalData,
                [section]: {
                    ...modalData[section],
                    [name]: value,
                },
            });
            return;
        }

        setModalData({
            ...modalData,
            [name]: value,
        });
    };

    const handlePrivileges = (data) => {
        const privArray = [];
        data.forEach((option) => {
            const privObject = {
                userID: modalData.userID,
                userRole: option,
            };
            privArray.push(privObject);
        });

        setValues({
            ...values,
            privilege: privArray,
        });

        setModalData({
            ...modalData,
            privilege: privArray,
        });
    };

    const generateDropdownOptions = (key, option, defaultValue) => ((key > 0)
        ? <option key={`${option}-${key}`} value={option}>{option}</option>
        : <><option key={defaultValue} value='default' disabled>{defaultValue}</option>
            <option key={`${option}-${key}`} value={option}>{option}</option>
        </>);


    const generateAccordionData = () => {
        const dataToProcess = [...searchFilteredDataSet.length > 0
            ? searchFilteredDataSet : usersList];
        const currentPageData = helperFunctions
            .splitDataSetForPagination(dataToProcess, 20)[currentPageNum];
        const elements = [];

        if (currentPageData && currentPageData[0]) {
            currentPageData[0].forEach((data, idx) => {
                elements.push({
                    sectionId: `userAccordionSection-${data.userID}-${idx}`,
                    header: generateAccordionSectionHeader(data),
                    content: generateAccordionSectionContent(data),
                });
            });
        }

        return elements;
    };

    const generateFilterPanel = () => <UserManagementFilterPanel open={filterPanelOpen}
        dataSet={(searchFilteredDataSet.length > 0)
            ? searchFilteredDataSet : usersList}
        performFilters={performFilters}
        closePanel={toggleFilterPanel}
        handleFilterResult={handleFilterPanelResult} />;

    const closeModal = () => {
        setShowModal(false);
        setValues(initialState);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formError = false;
        let errorMsg;

        if (!values.userRole) {
            formError = true;
            errorMsg = 'Please select an access level.';
        }

        if (!values.departmentName) {
            formError = true;
            errorMsg = 'Please select a department.';
        }

        if (formError === true) {
            Toast.fire({
                icon: 'error',
                titleText: errorMsg,
            });
            return null;
        }
        const createUser = {
            userDetails: {
                contactDetails: {
                    firstname: values.firstname,
                    surname: values.surname,
                    countryCode: values.countryCode,
                    mobile: values.mobile,
                },
                jobTitle: values.jobTitle,
                department: values.departmentName,
                email: values.email,
                userRole: values.userRole.toLowerCase(),
                privilege: values.privilege,

            },
        };
        const payLoadToSend = createUser;

        try {
            const response = await userManagementAPIs.createAccountByAdmin(payLoadToSend);
            if (response.status === 201) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'User created successfully.',
                });
                fetchUsers();
            } else if (response.data.ErrorCode === '2001') {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to create user. User with the email already exists.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to create user.',
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create user.',
            });
        }
        closeModal();
        return [];
    };

    const handleUpdate = async (event, content) => {
        event.preventDefault();
        const updatedUser = {
            userDetails: {
                contactDetails: {
                    ...content.contactDetails,
                    firstname: content.contactDetails.firstname,
                    surname: content.contactDetails.surname,
                    countryCode: content.contactDetails.countryCode,
                    mobile: content.contactDetails.mobile,
                },
                email: content.email,
                jobTitle: content.jobTitle,
                department: content.departmentName,
                userRole: content.userRole.toLowerCase(),
                privilege: content.privilege,
            },
        };
        const payLoadToSend = updatedUser;
        try {
            const response = await userManagementAPIs.updateProfile(payLoadToSend, content.userID);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'User updated successfully.',
                });
                fetchUsers();
                if (content.userID === authContext.user?.id) {
                    const trySilentAuth = await gatewayAPIs.verify();
                    if (trySilentAuth.status === 200) {
                        authContext.silentLogin(trySilentAuth.data);
                    }
                }
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update user.',
            });
        }
        setShowUpdateModal(false);
        setEditClicked(false);
        setModalData({});
        setValues({});
    };

    const countryCodes = GeneralConstants.strings.callingCodes;

    const generateUser = () => <>
        <LabelledInput id='firstname'
            type={LabelledInputTypeText} label={Constants.firstName}
            breakColumn={true} onChange={(e) => handleContactDetails(e)}
            value={editClicked ? modalData.contactDetails.firstname : values.firstname || ''}
            placeholder={Constants.enterFirstName}
            required={true} />
        <LabelledInput id='surname'
            type={LabelledInputTypeText} label={Constants.surname}
            breakColumn={true} onChange={(e) => handleContactDetails(e)}
            value={editClicked ? modalData.contactDetails.surname : values.surname || ''}
            placeholder={Constants.enterLastName}
            required={true} />
        <LabelledInput id='email'
            type={LabelledInputTypeText} label={Constants.emailAddress}
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={editClicked ? modalData.email : values.email || ''}
            placeholder={Constants.enterEmailAddress}
            required={true}
            readonly= {editClicked} />
        <LabelledInput id='jobTitle'
            type={LabelledInputTypeText} label={Constants.jobTitle}
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={editClicked ? modalData.jobTitle : values.jobTitle || ''}
            required={true}
            placeholder={Constants.enterJobTitle}/>
        <LabelledSelect id='countryCode'
            label={Constants.countryCode}
            breakColumn={true} onChange={(e) => handleChange(e,
                GeneralConstants.CONTACT_DETAILS)}
            value={(editClicked ? modalData.contactDetails.countryCode
                : values.countryCode) || GeneralConstants.DEFAULT}
            required={true}
            options={countryCodes
                .map((option, idx) => generateDropdownOptions(
                    idx, option, Constants.selectCountryCode,
                ))} />
        <LabelledInput id='mobile'
            type={LabelledInputTypeNumber} label={Constants.phoneNumber}
            breakColumn={true} onChange={(e) => handleChange(e,
                GeneralConstants.CONTACT_DETAILS)}
            value={editClicked ? modalData.contactDetails.mobile : values.mobile || ''}
            placeholder={Constants.enterPhoneNumber}
            required={true} />
        <LabelledSelect id='departmentName'
            label={Constants.department}
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={editClicked ? modalData.departmentName : values.departmentName || 'default'}
            required={true}
            options={departmentList
                .map((option, idx) => generateDropdownOptions(
                    idx, option, Constants.selectDepartment,
                ))} />
        {accountType.toLowerCase() !== 'supplier' && <div className='muSelectSearch'>
            <label className='privilegeLabel title selectSearchTitle'>{Constants.specialAccountPrivileges}</label>
            <SelectSearch
                id='specialPrivileges'
                options={specialAccountPrivileges || []}
                closeOnSelect={false}
                printOptions='on-focus'
                multiple
                placeholder={Constants.selectPrivileges}
                onChange={(item) => handlePrivileges(item)}
                z-index='40'
                value={editClicked ? modalData.privilege && modalData.privilege.map((el) => el.userRole) : values?.privilege?.map((el) => el.userRole) || 'default'}
            />
        </div>
        }
        {/* <LabelledSelect id='specialPrivileges'
            label='Special Privileges'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={editClicked ?
            modalData.specialPrivileges : values.specialPrivileges || 'default'}
            required={true}
            options={specialAccountPrivileges
                .map((option, idx) =>
            generateDropdownOptions(idx, option, 'Select Privilege'))} /> */}

        <LabelledSelect id='userRole'
            label='Users Access Level'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={editClicked ? helperFunctions.camel2title(modalData.userRole) : values.userRole || 'default'}
            required={true}
            readonly={modalData.userID === authContext.user.id}
            options={accessLevels
                .map((option, idx) => generateDropdownOptions(idx, option, 'Select Access Levels'))}/>
    </>;

    return <section id='userManagementContainer'>
        <div id='userManagementBtnContainer'><Button
            id='actionBtn'
            variant='secondary'
            label={'New User'}
            icon={true}
            iconSrc={addIcon}
            additionalVariant={'skinless'}
            handleClick={handleModal} /></div>
        {usersList && usersList.length > 0 && <section className='userContentSection' id={'userContent'}>
            <div className='userContentHeader'>
                <div className='searchBarContainer'>
                    <SearchBar id={'userManagementSearchBar'}
                        placeholder={'Search for a user'}
                        handleSearchResult={handleSearchFilterResult}
                    />
                </div>
                <div className='sortFilterContainer'>
                    <SortDropdown id={'usermanagementSortDropdown'}
                        options={Constants.sortOptions}
                        performSort={performSort}
                        dataSet={(searchFilteredDataSet.length > 0)
                            ? searchFilteredDataSet : usersList}
                        handleSortResult={handleSortResult}
                        handleSortCallback={() => null} />

                    <Button id={'usermanagementFilterButton'}
                        label='Filter'
                        icon={true}
                        iconSrc={filterIcon}
                        handleClick={() => toggleFilterPanel()} />
                </div>
            </div>
            <div className='userContentBody'>
                {noFilterResults
                    ? <p className='caption'>No User matching your search.</p>
                    : <Accordion id='userAccordion'
                        data={generateAccordionData()} />}
            </div>
            <div className='userContentFooter'>
                <Pagination id={'userManagementPagination'}
                    pages={(searchFilteredDataSet.length > 0)
                        ? helperFunctions.splitDataSetForPagination(searchFilteredDataSet,
                            20)
                        : helperFunctions
                            .splitDataSetForPagination(usersList, 20)}
                    currentPageNum={currentPageNum}
                    numOfDisplayPages={calculateNumDisplayPages()}
                    paginate={handlePagination} />
            </div>
            {generateFilterPanel()}
            {showUpdateModal && <Form onSubmit={(e) => handleUpdate(e, modalData)}><Modal
                id='updateUser'
                open={true}
                size='medium'
                headerTitle='Edit User'
                body={generateUser()}
                footer={true}
                mainActionButtonTxt='Update'
                secondActionButtonTxt='Cancel'
                secondActionButton={true}
                handleMainActionBtnClick={() => null}
                handleSecondaryActionBtnClick={() => {
                    setShowUpdateModal(false);
                    setEditClicked(false);
                    setModalData({});
                    setValues({});
                }}
                helpOption={false}
                closeModal={() => {
                    setShowUpdateModal(false);
                    setEditClicked(false);
                    setModalData({});
                    setValues({});
                }
                } /></Form>}
        </section >}
        {error && <p className='caption-large' id='bwError'>{error}</p>}
        {showModal && <Form onSubmit={handleSubmit}><Modal
            id='addUser'
            open={true}
            size='medium'
            headerTitle='New User'
            body={generateUser()}
            footer={true}
            mainActionButtonTxt='Create'
            secondActionButtonTxt='Cancel'
            secondActionButton={true}
            handleMainActionBtnClick={() => null}
            handleSecondaryActionBtnClick={closeModal}
            helpOption={false}
            closeModal={closeModal} /></Form>}
    </section>;
};

export default UserManagement;
